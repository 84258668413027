<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00781 12C10.9378 12 12.5078 10.43 12.5078 8.5C12.5078 6.57 10.9378 5 9.00781 5C7.07781 5 5.50781 6.57 5.50781 8.5C5.50781 10.43 7.07781 12 9.00781 12ZM9.00781 7C9.83781 7 10.5078 7.67 10.5078 8.5C10.5078 9.33 9.83781 10 9.00781 10C8.17781 10 7.50781 9.33 7.50781 8.5C7.50781 7.67 8.17781 7 9.00781 7ZM9.05781 17H4.77781C5.76781 16.5 7.47781 16 9.00781 16C9.11781 16 9.23781 16.01 9.34781 16.01C9.68781 15.28 10.2778 14.68 10.9878 14.2C10.2578 14.07 9.56781 14 9.00781 14C6.66781 14 2.00781 15.17 2.00781 17.5V19H9.00781V17.5C9.00781 17.33 9.02781 17.16 9.05781 17ZM16.5078 14.5C14.6678 14.5 11.0078 15.51 11.0078 17.5V19H22.0078V17.5C22.0078 15.51 18.3478 14.5 16.5078 14.5ZM17.7178 12.68C18.4778 12.25 19.0078 11.44 19.0078 10.5C19.0078 9.12 17.8878 8 16.5078 8C15.1278 8 14.0078 9.12 14.0078 10.5C14.0078 11.44 14.5378 12.25 15.2978 12.68C15.6578 12.88 16.0678 13 16.5078 13C16.9478 13 17.3578 12.88 17.7178 12.68Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'IcSupervisor',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped lang="scss"></style>
