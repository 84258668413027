<template>
  <div class="w-full">
    <div class="bg-white">
      <div class="responsive-section">
        <div
          class="min-h-25 py-2 px-8 flex flex-col xl:flex-row xl:justify-between space-y-4 xl:space-y-0 h-full items-center"
        >
          <div
            ref="chipsContainer"
            class="container"
          >
            <div class="w-full flex items-center gap-4">
              <chips-collection
                :style="`max-width: ${collectionMaxWidth}px; width: 100%`"
                label="Campaign"
                label-style="text-bb-text-secondary text-sm font-medium"
                chip-style="text-bb-text-default text-sm font-medium bg-neutral-0 rounded-full py-1 px-3"
                :chips="campaignNames"
                :max-width="collectionMaxWidth"
              />
              <chips-collection
                :style="`max-width: ${collectionMaxWidth}px; width: 100%`"
                label="Keywords"
                label-style="text-bb-text-secondary text-sm font-medium"
                chip-style="flex items-center gap-2 text-bb-text-default text-sm font-medium bg-neutral-0 rounded-full py-1 px-3"
                :chips="monitoringJobKeywords"
                :max-width="collectionMaxWidth"
              />
              <chips-collection
                v-if="monitoredCampaignLocations.length > 0"
                :style="`max-width: ${collectionMaxWidth}px; width: 100%`"
                label="Location"
                label-style="text-bb-text-secondary text-sm font-medium"
                chip-style="flex items-center gap-2 text-bb-text-default text-sm font-medium bg-neutral-0 rounded-full py-1 px-3"
                :chips="campaignLocations"
                :max-width="collectionMaxWidth"
              />
            </div>
          </div>
          <div
            class="flex gap-6 items-center justify-end"
            style="width: 270px"
          >
            <date-picker
              v-model="datePickerDates"
              range
              :clearable="false"
              :shortcuts="shortcuts"
              :disabled-date="disableDates"
              @input="pickDates"
            />
            <kebab
              :item-data="{}"
              :menu="menu"
            >
              <template #menu>
                <div
                  v-for="action in kebabMenu"
                  :key="action.value"
                  class="py-2 px-4 cursor-pointer hover:bg-bb-secondary-purple"
                >
                  <div
                    class="flex items-center gap-2"
                    @click="updateJobStatus(action.value)"
                  >
                    <component :is="action.icon" />
                    <p>{{ action.label }}</p>
                  </div>
                </div>
              </template>
            </kebab>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-neutral-0 relative responsive-section">
      <div class="mx-6 pt-6 flex justify-between">
        <page-tabs
          :tabs="pageTabs"
          :selected-tab="'overview'"
        >
          <div
            v-for="tab in pageTabs"
            :key="tab.value"
            class="cursor-pointer border-b-2 z-10"
            :class="
              $route.name === tab.value
                ? 'border-bb-brand-purple text-bb-brand-purple'
                : 'text-bb-text-secondary border-neutral-100 hover:border-neutral-50'
            "
          >
            <div
              class="px-6 pb-2 flex gap-2 items-center"
              @click="selectTab(tab.value)"
            >
              <component :is="tab.icon" />
              <p>
                {{ tab.label }}
              </p>
              <div
                v-if="tab.value === 'infringements-detector' && totalInfringements > 0"
                class="bg-neutral-50 text-xs text-bb-text-default font-medium h-6 w-6 rounded-full flex items-center justify-center"
              >
                <p>{{ totalInfringements }}</p>
              </div>
            </div>
          </div>
        </page-tabs>

        <p class="text-xs text-bb-neutral-gray">
          {{ activeSinceText }} {{ isMonitoringJobStopped ? pausedOnText : null }}
        </p>
      </div>
      <div class="flex justify-center">
        <div
          class="absolute h-0.5 bg-neutral-100 bottom-0"
          style="width: calc(100% - 48px)"
        ></div>
      </div>
    </div>
    <div
      v-if="isMonitoringJobStopped"
      class="px-6 pt-6 bg-neutral-0 responsive-section"
    >
      <paused-monitoring-job-banner @resume-monitoring="updateJobStatus('start')" />
    </div>
  </div>
</template>

<script>
import IcLive from '@/components/icon/brightbid/ic-live.vue'
import IcStop from '@/components/icon/ic-stop.vue'
import IcDonutChart from '@/components/icon/brightbid/ic-donut-chart.vue'
import IcSupervisor from '@/components/icon/brightbid/IcSupervisor.vue'
import IcWarning from '@/components/icon/brightbid/ic-warning.vue'
import PageTabs from '@/components/shared/PageTabs.vue'
import Kebab from '@/components/icon/brightbid/kebab.vue'
import ChipsCollection from '@/components/shared/ChipsCollection.vue'
import IcPin from '@/components/icon/brightbid/ic-pin.vue'
import DatePicker from 'vue2-datepicker'
import { COUNTRY_ID } from '@/utils/enums'
import PausedMonitoringJobBanner from '@/views/site/search/competitor_monitoring/overview/cards/PausedMonitoringJobBanner.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'SubHeader',
  components: {
    PausedMonitoringJobBanner,
    IcDonutChart,
    IcWarning,
    IcPin,
    ChipsCollection,
    Kebab,
    DatePicker,
    PageTabs,
    IcStop,
    IcLive,
    IcSupervisor,
  },
  props: {
    monitoredCampaignNames: {
      type: Array,
      default: () => [],
    },
    monitoredCampaignLocations: {
      type: Array,
      default: () => [],
    },
    activeSince: {
      type: String,
      default: '',
    },
    pausedOn: {
      type: String,
      default: '',
    },
    monitoringJobKeywords: {
      type: Array,
      default: () => [],
    },
    totalInfringements: {
      type: Number,
      default: 0,
    },
    isMonitoringJobStopped: {
      type: Boolean,
      default: false,
    },
    createdAt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      datePickerDates: [],
      COUNTRY_ID,
      selectedTab: 'overview',
      pageTabs: [
        { label: 'Overview', value: 'overview', icon: 'ic-donut-chart' },
        { label: 'Infringements Detector', value: 'infringements-detector', icon: 'ic-warning' },
        { label: 'Competitors List', value: 'all-competitors', icon: 'ic-supervisor' },
      ],
      menu: [
        { label: 'Start Monitoring', value: 'start', icon: 'IcLive' },
        { label: 'Stop Monitoring', value: 'stop', icon: 'IcStop' },
      ],
      shortcuts: [
        {
          text: 'Last 7 Days',
          onClick: () => {
            const today = new Date()
            const last7Days = new Date()
            last7Days.setDate(today.getDate() - 6)
            return [last7Days, today]
          },
        },
        {
          text: 'Last 14 Days',
          onClick: () => {
            const today = new Date()
            const last14Days = new Date()
            last14Days.setDate(today.getDate() - 13)
            return [last14Days, today]
          },
        },
        {
          text: 'Last 30 Days',
          onClick: () => {
            const today = new Date()
            const last30Days = new Date()
            last30Days.setDate(today.getDate() - 29)
            return [last30Days, today]
          },
        },
      ],
      collectionMaxWidth: 0,
    }
  },
  watch: {
    dateRange: {
      deep: true,
      immediate: true,
      handler() {
        this.datePickerDates = structuredClone(this.dateRange)
      },
    },
  },
  computed: {
    ...mapState('competitorMonitoring', ['dateRange']),
    campaignNames() {
      return this.monitoredCampaignNames.map(campaign => {
        return {
          label: campaign,
        }
      })
    },
    campaignLocations() {
      return this.monitoredCampaignLocations.map(campaign => {
        return {
          label: campaign,
        }
      })
    },
    kebabMenu() {
      if (this.isMonitoringJobStopped) {
        return this.menu.filter(item => item.value === 'start')
      }
      return this.menu.filter(item => item.value === 'stop')
    },
    activeSinceText() {
      return `Session Active Since: ${this.activeSince}`
    },
    pausedOnText() {
      return `- Paused on: ${this.pausedOn}`
    },
  },
  mounted() {
    this.updateDivWidth()

    this.$nextTick(() => {
      this.updateDivWidth()
    })

    window.addEventListener('resize', this.updateDivWidth)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateDivWidth)
  },
  methods: {
    ...mapActions('competitorMonitoring', ['resetDateRange']),
    ...mapActions('toast', ['loadToast']),
    updateDivWidth() {
      const width = this.$refs.chipsContainer.clientWidth
      this.collectionMaxWidth = width / 3
    },
    disableDates(date) {
      const startDate = new Date(this.createdAt)
      startDate.setDate(startDate.getDate() - 1)
      return date < startDate
    },
    async selectTab(tab) {
      switch (tab) {
        case 'overview':
          await this.$router.push({ name: 'overview' })
          break
        case 'infringements-detector':
          await this.$router.push({ name: 'infringements-detector' })
          break
        case 'all-competitors':
          await this.$router.push({ name: 'all-competitors' })
          break
        default:
          await this.$router.push({ name: 'overview' })
      }
    },
    async pickDates(dateRange) {
      // Less than the creation date
      const isLessThanCreationDate = dateRange[1] < new Date(this.createdAt)

      // Exceeds the current date
      const isExceedsCurrentDate = dateRange[0] > new Date()

      if (isLessThanCreationDate || isExceedsCurrentDate) {
        await this.resetDateRange()
        await this.loadToast({
          title: 'Warning',
          message: 'Invalid date range. Please select a valid date range. Default date range selected.',
          type: 'warning',
        })

        this.datePickerDates = this.dateRange
        this.$emit('pick-date', this.datePickerDates)
        return
      }

      this.datePickerDates = dateRange
      this.$emit('pick-date', dateRange)
    },
    async updateJobStatus(value) {
      this.$emit('update-monitoring-job-status', value)
    },
  },
}
</script>

<style lang="scss">
// $default-color: #f4f2ff;
$primary-color: $bb-brand-purple;
@import 'node_modules/vue2-datepicker/scss/index.scss';

.mx-input-wrapper {
  cursor: pointer;
  width: 220px;
}
.mx-datepicker-range {
  width: 220px;
}

.mx-input {
  border-radius: 8px;
  height: 40px;
  border: #e5e7eb solid 1px;
  padding: 8px 10px;
  font-size: 14px;
}

.mx-input:hover {
  border: $bb-brand-purple solid 2px;
}
.mx-input:focus {
  border: $bb-brand-purple solid 2px;
}

.container {
  width: 100%;
}

@media (min-width: 1200px) {
  .container {
    width: calc(100% - 270px);
  }
}
</style>
